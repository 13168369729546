(function () {
  const fairyEmojis = ["🧚", "🧚‍♀️", "🧚‍♂️", "✨"]; // Array of different fairy-related emojis
  let lastMousePosition = { x: 0, y: 0 };
  let lastTime = Date.now();

  // Function to generate random integers within a range
  function getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  // Function to get a random fairy emoji
  function getRandomFairyEmoji() {
    return fairyEmojis[Math.floor(Math.random() * fairyEmojis.length)];
  }

  // Function to create a fairy element
  function createFairy(x, y, speed) {
    const fairy = document.createElement("div");
    fairy.textContent = getRandomFairyEmoji(); // Use random fairy emoji
    fairy.style.position = "fixed"; // Ensure the position is fixed relative to the viewport
    fairy.style.left = `${x}px`;
    fairy.style.top = `${y}px`;

    // Randomize fairy size
    const size = getRandom(16, 100);
    fairy.style.fontSize = `${size}px`;

    fairy.style.opacity = "1";
    fairy.style.pointerEvents = "none"; // So it doesn't interfere with the page
    fairy.style.transition = "transform 1s, opacity 1s";
    document.body.appendChild(fairy);

    // Move fairy downwards and make it disappear
    setTimeout(() => {
      fairy.style.transform = `translateY(${getRandom(150, 250)}px) scale(${
        size / 24
      })`; // Adjust scale based on size
      fairy.style.opacity = "0";
    }, 10);

    // Remove the fairy after the animation ends
    setTimeout(() => {
      fairy.remove();
    }, 1000);
  }

  // Mouse movement event listener
  window.addEventListener("mousemove", function (e) {
    const currentTime = Date.now();
    const deltaX = e.clientX - lastMousePosition.x;
    const deltaY = e.clientY - lastMousePosition.y;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY); // Use Pythagorean theorem to get distance
    const timeDelta = currentTime - lastTime;

    // Calculate speed (pixels per millisecond)
    const speed = distance / timeDelta;

    // Ensure that at least one fairy spawns, even at very slow speed
    const numFairies = Math.max(1, Math.min(10, Math.floor(speed * 10))); // Max 10 fairies, at least 1

    for (let i = 0; i < numFairies; i++) {
      createFairy(e.clientX, e.clientY, speed); // Use clientX/clientY for accurate mouse position in the viewport
    }

    // Update the last mouse position and time
    lastMousePosition = { x: e.clientX, y: e.clientY };
    lastTime = currentTime;
  });
})();

// move to its own scriot

/*
const imgs = document.getElementsByClassName("flowers");
let hue = 0;

function rotateHue() {
  hue += 1; // Increment the hue
  if (hue >= 360) hue = 0; // Reset when it goes full circle

  // Apply the filter to each image
  Array.from(imgs).forEach((img) => {
    img.style.filter = `blur(7px) hue-rotate(${hue}deg)`;
  });

  // Call the function again after a short delay (smooth animation)
  requestAnimationFrame(rotateHue);
}

// Start the hue rotation
rotateHue();
*/
